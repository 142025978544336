import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { Key, memo, VFC, useCallback } from 'react';
import { UserCard } from '../components/organisms/user/UserCard';
import { UserDetailModal } from '../components/organisms/user/UserDetailModal';
import { users } from '../mock/users';

export const UserManagement: VFC = memo(() => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const onClickUser = useCallback(() => onOpen(), []);

    return (
        <>
            <Wrap p={{ base: 4, md: 10 }}>
                {users.map(
                    (user: {
                        id: Key | null | undefined;
                        imageUrl: string;
                        name: string;
                        job: string;
                    }) => (
                        <WrapItem key={user.id}>
                            <UserCard
                                imageUrl={user.imageUrl}
                                name={user.name}
                                job={user.job}
                                onClick={onClickUser}
                            ></UserCard>
                        </WrapItem>
                    ),
                )}
            </Wrap>
            <UserDetailModal isOpen={isOpen} onClose={onClose} />

            <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ユーザ詳細</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mx={4}>
                        <Stack spacing={4}>
                            <FormControl>
                                <FormLabel>名前</FormLabel>
                                <Input value="kouji" isReadOnly></Input>
                                <FormLabel>フルネーム</FormLabel>
                                <Input value="kouji" isReadOnly></Input>
                                <FormLabel>Mail</FormLabel>
                                <Input value="kouji@gmail.com" isReadOnly></Input>
                                <FormLabel>TEL</FormLabel>
                                <Input value="090-6732-9389" isReadOnly></Input>
                            </FormControl>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
});
