import axios from 'axios';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { textState } from '../pages/state/state';

export type ResponseUser = {
    access_token: string;
};

export const useAuth = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    // グローバルのstate
    const [text, setText] = useRecoilState(textState);
    console.log('text', text);
    const login = useCallback((name: string, password: string) => {
        setLoading(true);
        axios
            .post<ResponseUser>(`${process.env.REACT_APP_SERVER_URL}/api/login`, {
                name: name,
                password: password,
            })
            .then(async res => {
                if (res.data) {
                    setText(res.data.access_token);
                    history.push('/home');
                } else {
                    alert('ログインに失敗しました');
                }
            })
            .catch(() => {
                alert('ログインに失敗しました');
            });
    }, []);

    return { login, loading };
};
