import { memo, VFC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home } from '../pages/Home/index';
import { Page404 } from '../pages/Page404';
import { UserManagement } from '../pages/UserManagement';
import SimpleSidebar from '../components/SimpleSidebar';
import { Login } from '../pages/Login';

export const Router: VFC = memo(() => {
    return (
        <Switch>
            <Route exact path="/">
                <Login />
            </Route>
            <Route exact path="/home">
                <SimpleSidebar>
                    <Home />
                </SimpleSidebar>
            </Route>
            <Route exact path="/members">
                <SimpleSidebar>
                    <UserManagement />
                </SimpleSidebar>
            </Route>
            <Route path="*">
                <SimpleSidebar>
                    <Page404 />
                </SimpleSidebar>
            </Route>
        </Switch>
    );
});
