import { Box, Image, Stack, Text } from '@chakra-ui/react';
import { memo, VFC } from 'react';

type Props = {
    // id: number;
    name: string;
    imageUrl: string;
    job: string;
    onClick: () => void;
};

export const UserCard: VFC<Props> = memo(props => {
    const { name, imageUrl, job, onClick } = props;
    return (
        <Box
            w="260px"
            h="260px"
            bg="white"
            borderRadius="10px"
            shadow="md"
            p={4}
            _hover={{ cursor: 'pointer', opacity: 0.8 }}
            onClick={onClick}
        >
            <Stack textAlign="center">
                <Image
                    borderRadius="full"
                    boxSize="160px"
                    src={imageUrl}
                    alt="プロフィール画像"
                    m="auto"
                />
                <Text fontSize="lg" fontWeight="bold">
                    {name}
                </Text>
                <Text fontSize="sm" color="gray">
                    {job}
                </Text>
            </Stack>
        </Box>
    );
});
