/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, VFC } from 'react';
import { Button, Link } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { textState } from '../state/state';

const memberHp = 'https://sites.google.com/view/gotenroku-members-only';
const reservationStatusUrl =
    'https://calendar.google.com/calendar/u/0/embed?ctz=Asia/Tokyo&src=5.6.gotenroku@gmail.com';
const goodsUrl = 'https://gotenroku.booth.pm/';
const eventUrl =
    'https://calendar.google.com/calendar/u/0/embed?ctz=Asia/Tokyo&src=fg207joc054u884q5d87374u5c@group.calendar.google.com';
const variousReservationsUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLSe9Wy9ms3mkITG1aBR5vo4qCtXSnlxazWKoJIMrdh0bxQFpqQ/viewform';
const faqUrl = 'https://sites.google.com/view/gotenroku-members-only/faq?authuser=0';

export const LinkList: VFC = memo(() => {
    const history = useHistory();
    const onClickUserManagement = useCallback(() => history.push('/user_management'), []);
    const handleLogout = () => {
        history.push('/');
    };
    const [text, setText] = useRecoilState(textState);
    console.log('home text: ', text);

    return (
        <>
            <Link color="teal.500" href={memberHp} isExternal size="20px">
                会員用HP
            </Link>
            <br />
            <Link color="teal.500" href={reservationStatusUrl} isExternal>
                予約状況
            </Link>
            <br />
            <Link color="teal.500" href={goodsUrl} isExternal>
                Goods
            </Link>
            <br />
            <Link color="teal.500" href={eventUrl} isExternal>
                イベント
            </Link>
            <br />
            <Link color="teal.500" href={variousReservationsUrl} isExternal>
                各種予約
            </Link>
            <br />
            <Link color="teal.500" href={faqUrl} isExternal>
                FAQ
            </Link>
            <br />

            <Link color="teal.500" onClick={onClickUserManagement}>
                ユーザ一覧
            </Link>
            <br />
            <Button bg="teal.400" color="white" _hover={{ opacity: 0.8 }} onClick={handleLogout}>
                ログアウト
            </Button>
        </>
    );
});
