export const users = [
    {
        id: 1,
        company: '読売巨人軍',
        name: 'ジャビット',
        imageUrl: 'https://pics.prcm.jp/utumitetuya26/26007185/jpeg/26007185_480x518.jpeg',
        job: '新聞屋',
        introduction: '読売巨人軍のマスコットしています。よろしくお願いします。',
    },
    {
        id: 2,
        name: 'トラッキー',
        company: '阪神タイガース',
        imageUrl: 'https://www.konami.com/pawa/app/_img/torakki/chara_torakki.png',
        job: '電鉄',
        introduction: '阪神タイガースのマスコットしています。よろしくお願いします。',
    },
    {
        id: 3,
        company: '中日ドラゴンズ',
        name: 'ドアラ',
        imageUrl:
            'https://image.yodobashi.com/product/100/000/001/004/483/104/100000001004483104_10203.jpg',
        job: '新聞屋',
        introduction: '中日ドラゴンズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 4,
        company: '広島東洋カープ',
        name: 'カープ坊や',
        imageUrl: 'https://pics.prcm.jp/1479134893cba/63004746/png/63004746_220x216.png',
        job: '運送屋',
        introduction: '広島東洋カープのマスコットしています。よろしくお願いします。',
    },
    {
        id: 5,
        company: '東京ヤクルトスワローズ',
        name: 'つば九郎',
        imageUrl: 'https://www-static.yakult-swallows.co.jp/hp/img/common/logo_pet.png',
        job: 'ヤクルトレディ',
        introduction: '東京ヤクルトスワローズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 6,
        company: '横浜DeNaベイスターズ',
        name: 'ホッシー',
        imageUrl: 'http://livedoor.blogimg.jp/doragonflash/imgs/0/0/003004ab.jpg',
        job: 'エンジニア',
        introduction: '横浜DeNaベイスターズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 7,
        company: '日本ハムファイターズ',
        name: 'B.B',
        imageUrl: 'https://m.media-amazon.com/images/I/41HFdJQLkOL.jpg',
        job: 'ハム屋',
        introduction: '日本ハムファイターズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 8,
        company: '埼玉西武ライオンズ',
        name: 'レオ',
        imageUrl: 'https://i.pinimg.com/originals/0b/43/d3/0b43d3093b006ff7e8a8956110c29bae.jpg',
        job: '電鉄',
        introduction: '埼玉西武ライオンズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 9,
        company: '千葉ロッテマリーンズ',
        name: 'マー君',
        imageUrl: 'https://i.pinimg.com/564x/b0/55/38/b05538c06774713e9bc567b080865055.jpg',
        job: 'お菓子屋',
        introduction: '千葉ロッテマリーンズのマスコットしています。よろしくお願いします。',
    },
    {
        id: 10,
        company: '福岡ソフトバンクホークス',
        name: 'ハリーホーク',
        imageUrl: 'https://pics.prcm.jp/9057c06e26a5c/84692616/png/84692616_220x159.png',
        job: 'エンジニア',
        introduction: '福岡ソフトバンクホークスのマスコットしています。よろしくお願いします。',
    },
    {
        id: 11,
        company: '東北楽天ゴールデンイーグルス',
        name: 'クラッチ',
        imageUrl: 'https://tshop.r10s.jp/gold/rakuteneagles/itemimg/item/2017/j0100208_a.jpg',
        job: 'エンジニア',
        introduction: '東北楽天ゴールデンイーグルスのマスコットしています。よろしくお願いします。',
    },
    {
        id: 12,
        company: 'オリックスバッファローズ',
        name: 'バッファローブル',
        imageUrl:
            'https://i-ogp.pximg.net/c/540x540_70/img-master/img/2011/07/16/16/53/47/20348619_p0_square1200.jpg',
        job: '保険屋',
        introduction: 'オリックスバッファローズのマスコットしています。よろしくお願いします。',
    },
];
