import { Button, Flex, Heading, Input, useColorModeValue } from '@chakra-ui/react';
import { memo, useState, VFC } from 'react';
import { useAuth } from '../../hooks/useAuth';

export const LoginButton: VFC = memo(() => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { login } = useAuth();

    const handleLogin = () => login(email, password);

    const formBackground = useColorModeValue('gray.100', 'gray.700');

    return (
        <>
            <Flex h="100vh" alignItems="center" justifyContent="center">
                <Flex
                    flexDirection="column"
                    bg={formBackground}
                    p={12}
                    borderRadius={8}
                    boxShadow="lg"
                >
                    <Heading mb={6}>Log In</Heading>
                    <Input
                        placeholder="gotenroku@gmail.com"
                        type="email"
                        variant="filled"
                        mb={3}
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                        }}
                    />
                    <Input
                        placeholder="**********"
                        type="password"
                        variant="filled"
                        mb={6}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button colorScheme="teal" mb={8} onClick={handleLogin}>
                        Log In
                    </Button>
                </Flex>
            </Flex>
        </>
    );
});
