import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './router/Router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';

function App() {
    const queryClient = new QueryClient();

    return (
        <div className="App">
            <RecoilRoot>
                <QueryClientProvider client={queryClient}>
                    <ChakraProvider>
                        <BrowserRouter>
                            <Router />
                        </BrowserRouter>
                    </ChakraProvider>
                </QueryClientProvider>
            </RecoilRoot>
        </div>
    );
}

export default App;
